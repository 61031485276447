import React from 'react';
import '../../App.scss';
import './Footer.scss';

export const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer__intro'>
                <div className='footer__intro-one'>
                    <img src={require(`../../assets/truman_logo_light.png`)} alt="Truman logo"></img>
                    <p>Truman Health is a Canadian men's healthcare company, dedicated to advising and treating men online — completely and discreetly.</p>
                </div>
                <div className='footer__intro-two'>
                    <p>hello@trumanhealth.ca</p>
                    <p>88 Blue Jays Way, Toronto, ON M5V 2G3</p>
                </div>
            </div>
            <div className='footer__links'>
                <div className='footer__links-column'>
                    <h2>Company</h2>
                    <ul>
                        <li><a href='/about'>About us</a></li>
                        <li><a href='/blog'>Our blog</a></li>
                        <li><a href='https://lockebio.atlassian.net/servicedesk/customer/user/login?destination=portals%3Fundefined'>Help</a></li>
                    </ul>
                </div>
                <div className='footer__links-column'>
                    <h2>What we treat</h2>
                    <ul>
                        <li><a href='/erectile-dysfunction'>Erectile Dysfunction</a></li>
                        <li><a href='/hair-loss'>Hair Loss</a></li>
                        <li><a href='/smoking-cessation'>Smoking Cessation</a></li>
                    </ul>
                </div>
                <div className='footer__links-column'>
                    <h2>Support</h2>
                    <ul>
                        <li><a href='/shipping-refund-policy'>Shipping and Refund Policy</a></li>
                        <li><a href='/terms'>Terms and Conditions</a></li>
                        <li><a href='/privacy-policy'>Privacy Policy </a></li>
                    </ul>
                </div>
                <div className='footer__links-column'>
                    <h2>Stay Connected</h2>
                    <ul className='footer__links-social'>
                        <li><a target='__blank' rel="noopener noreferrer" href='https://www.instagram.com/trumanhealth'><i class="fab fa-instagram fa-lg"></i></a></li>
                        <li><a target='__blank' rel="noopener noreferrer" href='https://twitter.com/health_truman'><i class="fab fa-twitter fa-lg"></i></a></li>
                        <li><a target='__blank' rel="noopener noreferrer" href='https://www.facebook.com/TrumanHealth'><i class="fab fa-facebook-f fa-lg"></i></a></li>
                    </ul>
                </div>
            </div>
            <div className='footer__copyright'>
                <div>
                    <p>Proudly Canadian</p>
                    <p className='footer__copyright-accent'>All content © Truman Health </p>
                </div>
                <div>
                    <a href='https://www.legitscript.com/?undefined'><img src="https://static.legitscript.com/seals/26686598.png" alt="LegitScript approved"></img></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;