import React from 'react';
import '../../App.scss';
import { Hero } from '../../components/hero/Hero';
import { SecondaryTextPanel } from '../../components/text_panels/TextPanels';
import { RXCard } from '../../components/rx_cards/RXCard';
import { HowItWorks } from '../../components/how_it_works/HowItWorks'
import { Footer } from '../../components/footer/Footer';
import PreFooter from '../../components/prefooter/PreFooter';

function SmokingCessation() {
    // const { scrollYProgress } = useScroll();
    // const scaleX = useSpring(scrollYProgress, {
    //     stiffness: 100,
    //     damping: 30,
    //     restDelta: 0.001
    // });
    return (
        <div className='page'>
            {/* <motion.div className="progress-bar" style={{ scaleX }} /> */}
            <Hero
                heading="Cut the smoke without the stress."
                subheading="Smoking cessation solutions to get you back to your healthiest self."
                bannerImage="smokingcessation_banner"
                bannerLink="https://truman.lockebio.com/onboarding/condition/77b8caef-0cc5-4bcb-b2d0-da80a6179373/select-medication" />
            <div className='single-card rx-cards-container'>
                <RXCard
                    drugImage="rx_image"
                    drugName="Varenicline"
                    drugDescription="Daily tablets to help you effectively quit smoking and lower your risk of heart and lung disease, including cancer"
                    drugUsage="Daily"
                    drugStrength="3 month supply"
                    drugPrice="$299"
                    cardLink="https://truman.lockebio.com/onboarding/condition/77b8caef-0cc5-4bcb-b2d0-da80a6179373/select-medication"
                />
            </div>
            <div className='text-panel-container'>
                <SecondaryTextPanel
                    heading="What does smoking do to you health?"
                    paragraph="You’ve likely heard this before, but it’s worth repeating: smoking can lead to heightened risks of heart and lung disease and substantially increases the risk of developing cancer. With the right help, you have the power to stop smoking."
                    buttonLabel="I WANT TO QUIT"
                    image="smoking_cessation_panel_img_one"
                    altText="Hand crushing cigarettes"
                    direction="left"
                    panelLink="https://truman.lockebio.com/onboarding/condition/77b8caef-0cc5-4bcb-b2d0-da80a6179373/select-medication"
                />
                <SecondaryTextPanel
                    heading="You deserve a healthy lifestyle."
                    paragraph="Whether you're a light or heavy smoker looking to quit, we're dedicated to supporting your pursuit of better health. It's time to kick your smoking habit to the curb and give yourself back years of your life"
                    buttonLabel="SHOP NOW"
                    image="smoking_cessation_panel_img_two"
                    altText="Hands snapping a cigarette in half"
                    direction="right"
                    panelLink="https://truman.lockebio.com/onboarding/condition/77b8caef-0cc5-4bcb-b2d0-da80a6179373/select-medication"
                />
                <SecondaryTextPanel
                    heading="How do our solutions work?"
                    paragraph="Our Varenicline Tablets are an effective way to help you put down cigarettes for good. Our Nicotine Chewing Gum aids i managing withdrawal symptoms and cravings to keep you feeling great on your road to recovery. Get started today."
                    buttonLabel="FIND MY TREATMENT"
                    image="smoking_cessation_panel_img_three"
                    altText="Hand shaking pills into other hand"
                    direction="left"
                    panelLink="https://truman.lockebio.com/onboarding/condition/77b8caef-0cc5-4bcb-b2d0-da80a6179373/select-medication"
                />
                <SecondaryTextPanel
                    heading="What is the treatment process?"
                    paragraph="It’s simple. Take the Varenicline Tablets as prescribed by our healthcare professionals and chew the Nicotine Gum as recommended, decreasing use as your body adjusts. We’re here to support your shift to a healthy lifestyle and to keep the process as seamless as possible."
                    buttonLabel="FIND MY TREATMENT"
                    image="smoking_cessation_panel_img_four"
                    altText="Hand snapping cigarette in half"
                    direction="right"
                    panelLink="https://truman.lockebio.com/onboarding/condition/77b8caef-0cc5-4bcb-b2d0-da80a6179373/select-medication"
                />
            </div>
            <HowItWorks />
            <PreFooter />
            <Footer />
        </div>
    );
}

export default SmokingCessation;